import cx from 'classnames';
import styles from './style.module.scss';

const Container = ({ fluid, size, className, ...props }) => {
    return (
        <div
            className={cx(styles.Container, className, {
                [styles['Container-lg']]: size === 'lg',
                [styles['Container-fluid']]: fluid
            })}
            {...props}
        />
    );
};

export default Container;
