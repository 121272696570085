import cx from 'classnames';
import styles from './style.module.scss';

const Color = ({ type = 'primary-300', bg = false, className, ...props }) => {
    return (
        <div
            className={cx('Color', className, {
                [styles['Color-' + type]]: !bg,
                [styles['Color-bg-' + type]]: bg
            })}
            {...props}
        />
    );
};

export default Color;



