import Center from 'components/center';
import Color from 'components/color';
import Container from 'components/container';
import styles from './style.module.scss';

function getYear() {
    const d = new Date();
    return d.getFullYear();
}

const Footer = () => {
    return (
        <div className={styles.Footer}>
            <Container className="inset-xl">
                <Center>
                    <Color type="primary-200">
                        <p>© {getYear()} Torre Financial</p>
                    </Color>
                </Center>
            </Container>
        </div>
    );
};

export default Footer;
