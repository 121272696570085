const dir = {
    Index: {
        path: '/',
        title: 'Home | Torre Financial - Investment Management',
        metadescription:
            'Torre Financial is an independent investment advisory firm focused on excellence. We provide investment management services.'
    },
    About: {
        path: '/about',
        title: 'About | Torre Financial - Investment Management',
        metadescription:
            'Founded in 2018, Torre Financial is an independent investment advisory firm registered with the states of California and Texas. Torre Financial focuses on providing high-quality investment management services to our clients.'
    },
    Philosophy: {
        path: '/philosophy',
        title: 'Philosophy | Torre Financial - Investment Management',
        metadescription:
            'Our investment philosophy is our set of beliefs about the markets and how we operate.'
    },
    Strategy: {
        path: '/strategy',
        title: 'Strategy | Torre Financial - Investment Management',
        metadescription: 'Insights into our investment approach and strategy.'
    },
    Newsletter: {
        path: '/newsletter',
        title: 'Newsletter | Torre Financial - Investment Management',
        metadescription:
            'Insights, company research, and updates from Torre Financial.'
    },
    Contact: {
        path: '/contact',
        title: 'Contact | Torre Financial - Investment Management',
        metadescription:
            'Torre Financial is located in Lafayette, California -- about half an hour from San Francisco.'
    },
    Login: {
        path: '/login',
        title: 'Login | Torre Financial - Investment Management',
        metadescription: 'Log in to continue.'
    },
    Sitemap: {
        path: '/',
        title: 'Sitemap | Torre Financial - Investment Management',
        metadescription: 'Sitemap'
    }
};
export default dir;
