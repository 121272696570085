import cx from 'classnames';
import dir from 'utils/dir';
import Container from 'components/container';
import Link from 'next/link';
import styles from './style.module.scss';
import Image from 'next/image';

const Header = () => {
    return (
        <div className={cx(styles.Header, 'stacked-xl')}>
            <Container fluid className={cx(styles['Header-line'])}>
                <div className={cx(styles['Header-logo'])}>
                    <Link href={dir.Index.path}>
                        <Image
                            src="/images/logos/logo-full.svg"
                            width="300"
                            height="27"
                        />
                    </Link>
                </div>
                <div className={cx(styles['Header-main'])}>
                    <Link
                        href={dir.About.path}
                        className={cx(styles['Header-item'])}
                    >
                        About
                    </Link>
                    <Link
                        href={dir.Philosophy.path}
                        className={cx(styles['Header-item'])}
                    >
                        Philosophy
                    </Link>
                    <Link
                        href={dir.Strategy.path}
                        className={cx(styles['Header-item'])}
                    >
                        Strategy
                    </Link>
                    <Link
                        href={dir.Newsletter.path}
                        className={cx(styles['Header-item'])}
                    >
                        Newsletter
                    </Link>
                    <Link
                        href={dir.Contact.path}
                        className={cx(styles['Header-item'])}
                    >
                        Contact
                    </Link>
                </div>
            </Container>
        </div>
    );
};

export default Header;
