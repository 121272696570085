import React, { useEffect } from 'react';
import Head from 'next/head';
import Header from 'components/header';
import Footer from 'components/footer';
// import { initGA, logPageView } from 'utils/analytics';
import './styles.scss';

// initGA();

function MyApp({ Component, pageProps }) {
    // useEffect(() => {
    //     logPageView();
    // }, []);

    return (
        <div>
            <Head title="Torre Financial">
                <meta name="description" content="Torre Financial" />
                <meta name="keywords" content="Investment Advisory Firm" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/images/icons/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/images/icons/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/images/icons/favicon-16x16.png"
                />
            </Head>
            <Header />
            <Component {...pageProps} />
            <Footer />
        </div>
    );
}

export default MyApp;
